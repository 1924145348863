import styled from 'styled-components';
import { BlockText, InnerText, OutsideLabel } from './atoms';
import { COLOUR_OFF_WHITE } from '../../../styles';

const SeoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  max-width: 500px;
  gap: 20px;
  margin: auto;
  margin-top: 150px;
`

const Heading = styled.h1`
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  color: ${COLOUR_OFF_WHITE};
  text-align: justify;
`

const SubHeading = styled.h2`
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  color: ${COLOUR_OFF_WHITE};
  text-align: justify;
`

const SeoContent = () => {
  return (
    <SeoContentContainer>
      <Heading>Sorta Fluent</Heading>
      <SubHeading>More infromation about our application:</SubHeading>
      <OutsideLabel>'Sorta Fluent': Learn the Rules and Improve Grammar with AI</OutsideLabel>
      <BlockText>
        <InnerText>
          'Sorta Fluent' isn't just another grammar correction tool. It's an AI-powered solution that not only fixes your mistakes but also teaches you the rules behind them. Say goodbye to repeating the same errors—our app helps you understand why corrections are made, so you can improve your language skills with every interaction.
        </InnerText>
      </BlockText>

      <OutsideLabel>Why Choose 'Sorta Fluent'?</OutsideLabel>
      <BlockText>
        <InnerText>
          Tired of relying on multiple apps to piece together a translation? With 'Sorta Fluent,' you get everything in one place. Whether it's correcting your grammar, offering simple translations, or explaining language rules, our app takes the guesswork out of learning a language. The more you use it, the more you'll understand, making you less dependent on the app over time!
        </InnerText>
      </BlockText>

      <OutsideLabel>How Does 'Sorta Fluent' Work?</OutsideLabel>
      <BlockText>
        <InnerText>
          Powered by AI, 'Sorta Fluent' provides instant feedback on your language usage. Whether you’re writing an email, composing a social media post, or studying for an exam, the app corrects mistakes and explains why those corrections were made. Plus, it makes learning fun with its simple, intuitive interface, making language mastery an enjoyable experience.
        </InnerText>
      </BlockText>

      <OutsideLabel>Supported Languages – Learn from Arabic to Vietnamese</OutsideLabel>
      <BlockText>
        <InnerText>
          'Sorta Fluent' supports a wide range of languages, making it a versatile tool for learners across the globe. You can use the app to correct grammar, translate, and learn language rules in: Arabic, Bengali, Bulgarian, Chinese, English, French, German, Japanese, and many more! You can also disable the languages you don't need in the settings to streamline your learning experience.
        </InnerText>
      </BlockText>

      <OutsideLabel>Learn With Confidence – Practice Foreign Languages with Ease</OutsideLabel>
      <BlockText>
        <InnerText>
          Our goal at 'Sorta Fluent' is to make learning a foreign language as seamless and effective as possible. Whether you're learning for business, travel, or personal development, our AI-powered tool ensures that you not only become fluent but also understand the language rules along the way.
        </InnerText>
      </BlockText>

      <OutsideLabel>Start Learning Smarter with 'Sorta Fluent' Today</OutsideLabel>
      <BlockText>
        <InnerText>
          Say goodbye to memorizing endless grammar rules and awkward translations. With 'Sorta Fluent,' you'll learn smarter, not harder, thanks to AI technology that teaches you as you go. Embrace a fun, effective, and engaging way to master your target language!
        </InnerText>
      </BlockText>
    </SeoContentContainer>
  );
};

export default SeoContent;
