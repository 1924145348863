import { createGlobalStyle } from 'styled-components';
import Sidepanel from './pages/Sidepanel/Sidepanel';
import { COLOUR_BACKGROUND_GRAY } from './styles';
import SeoContent from './pages/Sidepanel/shared/SeoContent';

const GlobalStyle = createGlobalStyle`
  /* Reset body margin and padding */
  body {
    margin: 0;
    padding: 0;
    background: ${COLOUR_BACKGROUND_GRAY};
  }

  /* Make body fill the entire viewport */
  html, body, #root {
    height: 100%;
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Sidepanel />
      <SeoContent />
    </>
  );
}

export default App;
