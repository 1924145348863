import styled from "styled-components";
import { COLOUR_HIGHLIGHTS_GRAY, COLOUR_OFF_WHITE } from "../../../styles";

export const OutsideLabel = styled.h3`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin-bottom: 4px;
  color: ${COLOUR_OFF_WHITE};
  font-weight: bold;
`;

export const BlockText = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLOUR_HIGHLIGHTS_GRAY};
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  max-height: 300px;
  margin-bottom: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 32px;
  align-items: center;
`;

export const InnerText = styled.p`
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: ${COLOUR_OFF_WHITE};
  text-align: justify;
`;