import React, { useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';

import PrimaryButton from '../shared/PrimaryButton';
import logo from '../../../assets/img/logo.svg';
import { initTracking } from '../../../initTracking';
import { LANGUAGE_LIST, Language } from '../../../core';
import { COLOUR_OFF_WHITE } from '../../../styles';
import { Options } from '../shared/Options';
import { Scene } from '../Sidepanel';
import SceneNavigation from '../shared/SceneNavigation';
import { Agent, getAgent, readLocalStorage, writeLocalStorage } from '../shared/helpers';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
`;

const Logo = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 130px;
`;

const TextArea = styled.textarea`
  border: 1px solid #d2d6dc;
  border-radius: 4px;
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  resize: none;
  width: calc(100% - 12px);
  max-width: 500px;
  padding: 8px;
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const DropDownLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin-bottom: 4px;
  color: ${COLOUR_OFF_WHITE};
`;

const Dropdown = styled.select`
  width: 294px;
  padding: 8px;
  border: 1px solid ${COLOUR_OFF_WHITE};
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

const FLAGS = {
  Arabic: '🇦🇪',
  Bengali: '🇧🇩',
  Bulgarian: '🇧🇬',
  Chinese: '🇨🇳',
  'Swiss German': '🇨🇭',
  Czech: '🇨🇿',
  Danish: '🇩🇰',
  German: '🇩🇪',
  French: '🇫🇷',
  Finnish: '🇫🇮',
  English: '🇬🇧',
  Croatian: '🇭🇷',
  Hungarian: '🇭🇺',
  Indonesian: '🇮🇩',
  Hebrew: '🇮🇱',
  Hindi: '🇮🇳',
  Italian: '🇮🇹',
  Japanese: '🇯🇵',
  Kazakh: '🇰🇿',
  Korean: '🇰🇷',
  Dutch: '🇳🇱',
  Norwegian: '🇳🇴',
  Urdu: '🇵🇰',
  Polish: '🇵🇱',
  'Portuguese (Brazilian)': '🇧🇷',
  'Portuguese (European)': '🇵🇹',
  Russian: '🇷🇺',
  Slovak: '🇸🇰',
  Spanish: '🇪🇸',
  Swedish: '🇸🇪',
  Thai: '🇹🇭',
  Turkish: '🇹🇷',
  Vietnamese: '🇻🇳',
};

export type FormValues = {
  language: Language;
  text: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  onChangeScene: (scene: Scene) => void;
};

const FormScene = ({ onSubmit, onChangeScene }: Props) => {
  const [language, setLanguage] = useState<Language | ''>('');
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);
  const [text, setText] = useState('');

  const m = initTracking();

  useEffect(() => {
    (async () => {
      const rawList = await readLocalStorage('languageList');
      const languageList = rawList && JSON.parse(rawList);
      const configuredLanguages = languageList || LANGUAGE_LIST;
      setAvailableLanguages(configuredLanguages);
      const language = await readLocalStorage('language') as Language;
      if (language && configuredLanguages?.includes(language)) {
        m.track('action.languageAutoSet', { lang: language });
        setLanguage(language);
      }
    })();
  }, []);

  useEffect(() => {
    m.track('flow.form');
  }, []);

  useEffect(() => {
    if(getAgent() === Agent.CE) {
      chrome.runtime.onMessage.addListener((request) => {
        m.track('action.selectedTextRecieved');
        if (request.action === 'passSelectedText') {
          setText(request.data.text);
        }
      })
    }
  }, []);

  const handleLanguageChange = async (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const lang = event.target.value as unknown as Language;
    m.track('action.languageSelect', { lang });
    setLanguage(lang);
    await writeLocalStorage('language', lang);
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    if (language) {
      const values: FormValues = { language, text };
      onSubmit(values);
    }
  };

  return (
    <FormContainer>
      <Options scenes={['settings', 'history']} onChangeScene={onChangeScene} />
      <Logo width="130px" height="19px" src={logo} loading="lazy" alt="Sorta Fluent" title="Sorta Fluent" />
      <SceneNavigation currentScene="explain" onChangeScene={onChangeScene} />
      <DropDownContainer>
        <DropDownLabel>Select language:</DropDownLabel>
        <Dropdown value={language} onChange={handleLanguageChange}>
          <option disabled value={''}>
            {' '}
            -- Pick a language (required) --{' '}
          </option>
          {availableLanguages.map((l) => (
            <option value={l}>
              {FLAGS[l]} {l}
            </option>
          ))}
          <option disabled value={'never'}>
            -- Change available languages in Settings --
          </option>
        </Dropdown>
      </DropDownContainer>
      <TextArea
        value={text}
        onChange={handleTextChange}
        placeholder="Text to explain..."
        rows={4}
      />
      <PrimaryButton
        onClick={handleSubmit}
        isDisabled={!text || !language}
        text="Explain my text"
        type="magic"
      />
    </FormContainer>
  );
};

export default FormScene;
