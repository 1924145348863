import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { marked } from 'marked';

import PrimaryButton from '../shared/PrimaryButton';
import { initTracking } from '../../../initTracking';
import { Response } from '../../../core';

import CopyToClipboardButton from '../shared/CopyToClipboardButton';
import { Scene } from '../Sidepanel';
import { Options } from '../shared/Options';
import logo from '../../../assets/img/logo.svg';
import Promotions from '../shared/Promotions';
import { readLocalStorage } from '../shared/helpers';
import { OutsideLabel, BlockText, InnerText } from '../shared/atoms';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px 0px 8px 0;
  width: 100%;
  max-width: 518px;
`;

const Logo = styled.img`
  width: 130px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;


const FirstLabel = styled(OutsideLabel)`
  margin-top: 20px;
`;

type Props = {
  response: Response;
  onBack?: () => void;
  onChangeScene: (scene: Scene) => void;
};

const ResponseScene = ({ response, onBack, onChangeScene }: Props) => {
  const [isShowingPromo, setIsShowingPromo] = useState(false);
  // Without onBack, it's history view. TODO: refactor this
  const isInHistoryView = !onBack;

  const m = initTracking();

  useEffect(() => {
    if (!isInHistoryView) {
      m.track('flow.result');
    }
    (async () => {
      if (!isInHistoryView) {
        const rawHistory = await readLocalStorage('history');
        if (rawHistory) {
          const history = JSON.parse(rawHistory);
          if (history && history.length > 5) {
            setIsShowingPromo(true);
          }
        }
      }
    })();
  }, []);

  const html = marked(response?.explanation + '<br>‎');

  return (
    <Container>
      {!isInHistoryView && (
        <Options
          scenes={['correct', 'settings', 'history']}
          onChangeScene={(s: Scene) => {
            onChangeScene(s);
            onBack();
          }}
        />
      )}
      {!isInHistoryView && (
        <Logo
          src={logo}
          alt="Sorta Fluent"
          onClick={() => {
            onBack();
          }}
        />
      )}
      {!isInHistoryView && <FirstLabel>{'✍️ Your text:'}</FirstLabel>}
      {isInHistoryView && <OutsideLabel>{'✍️ Your text:'}</OutsideLabel>}
      <BlockText>
        <InnerText>{response.input}</InnerText>
        <CopyToClipboardButton textToCopy={response.input} />
      </BlockText>
      <OutsideLabel>🧠 Explanation:</OutsideLabel>
      <BlockText>
        {/* @ts-ignore next-line */}
        <InnerText dangerouslySetInnerHTML={{ __html: html }} />
      </BlockText>
      {!isInHistoryView && (
        <PrimaryButton onClick={onBack} text="Ask again" type="back" />
      )}
      {isShowingPromo && <Promotions />}
    </Container>
  );
};

export default ResponseScene;
