import axios from 'axios';
import { Language, Response } from '../../../core';

export async function chatGptExplain(
  language: Language,
  text: string,
  isRetry: boolean
): Promise<Response> {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://xpthzmjgbonkcraktv5hnpkuly0plihd.lambda-url.eu-west-1.on.aws/?mode=EXPLAIN&language=${encodeURIComponent(language)}&text=${encodeURIComponent(text)}`
      )
      .then(function (response) {
        const r = response.data as string;
        return resolve({
          ts: Date.now(),
          status: 'ok',
          type: 'explain',
          language,
          input: text,
          explanation: r.replaceAll('\\n', '\\'),
        });
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
}
